"use strict";

(function () {
  Turbolinks.start();

  var ready = function ready() {
    console.log("🦄 TurboLinks Ready -> " + window.location.href + " width: " + $(window).width());
    var pageWidth = $(window).width(); // We reset the scroll to 0 at every page load

    $('html, body').scrollTop(0);

    if (pageWidth >= 481) {
      setTimeout(function () {
        setAnimationList();
      }, 100);
    }

    $('a').bkoScroll();
    $("body").bkoCookies({
      bkoBgColor: '#BB9249'
    });
    trackPChange();
    $(".menu-mobile").bkoMenu();
    $('.mentions-legales-button').on('click', function () {
      $('.mentions-legales').toggleClass('openMentions');
      var y = $(window).scrollTop();
      $('html, body').animate({
        scrollTop: y + 150
      });
    }); // Slider

    var slideCount = $('#slider ul li').length;
    var slideWidth = $('#slider ul li').width();
    var slideHeight = $('#slider ul li').height();
    var sliderUlWidth = slideCount * slideWidth;
    $('#slider').css({
      width: slideWidth,
      height: slideHeight
    });
    $('#slider ul').css({
      width: sliderUlWidth,
      height: slideHeight,
      marginLeft: -slideWidth
    });
    $('#slider ul li:last-child').prependTo('#slider ul');

    function moveLeft() {
      $('#slider ul').animate({
        left: +slideWidth
      }, 200, function () {
        $('#slider ul li:last-child').prependTo('#slider ul');
        $('#slider ul').css('left', '');
      });
    }

    ;

    function moveRight() {
      $('#slider ul').animate({
        left: -slideWidth
      }, 200, function () {
        $('#slider ul li:first-child').appendTo('#slider ul');
        $('#slider ul').css('left', '');
      });
    }

    ;
    $('a.control_prev').click(function () {
      event.preventDefault();
      moveLeft();
    });
    $('a.control_next').click(function () {
      event.preventDefault();
      moveRight();
    }); // Textes docteurs lire la suite

    $('.column-item-more').on('click', function () {
      $(this).parent('.column-item-description').addClass('show');
    });
    lazywatcher();
  };

  var cache = function cache() {
    console.log("🦄 TurboLinks CacheLoad");
    resetAnimationList();
  };

  document.addEventListener("turbolinks:before-cache", cache);
  document.addEventListener("turbolinks:load", ready);
  $(window).on('scroll', function () {
    var fromTop = $(window).scrollTop();

    if ($(window).width() >= 481) {
      setAnimationList();
    }

    $('body').toggleClass("down", fromTop > 150);
  });
  /**
   * Uncoment to unable google analitycs & update gulpdile.js
   */
  // gaInit( '{yourUA}' );
})(jQuery);